import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/MeasureLayout";
import { ButtonLink } from "../components/Link";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Stck Programming Language`}</h1>
    <p>{`Stck is a minimal `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Stack-oriented_programming"
      }}>{`stack
based`}</a>{` `}<a parentName="p" {...{
        "href": "https://esolangs.org/wiki/Esoteric_programming_language"
      }}>{`esoteric
programming language`}</a>{`.
Data is stored on an unlimited number of stacks. Stacks can be pushed to,
popped from, or manipulated with operators.`}</p>
    <pre><code parentName="pre" {...{}}>{`"Hello, World!">o
`}</code></pre>
    <ButtonLink to="/playground" primary={1} mdxType="ButtonLink">
  Playground
    </ButtonLink>
    <h2>{`Getting Started`}</h2>
    <p>{`You can use Stck via the `}<a parentName="p" {...{
        "href": "/playground"
      }}>{`online playground`}</a>{`, install it with `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/stck"
      }}>{`NPM`}</a>{`,
or try it out with `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/npx"
      }}>{`NPX`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm i -g stck
`}</code></pre>
    <p>{`For information on the syntax, `}<a parentName="p" {...{
        "href": "/spec"
      }}>{`read the spec`}</a>{`.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`stck`}</inlineCode>{` command accepts a single filename as a parameter.`}</p>
    <p>{`The following command will run the file example.stck.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`stck example.stck
# OR
npx stck example.stck
`}</code></pre>
    <p>{`You can pass input to your program via stdin.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`echo "1\\n2\\nworld" | yarn start examples/hello.stck
`}</code></pre>
    <p>{`stdin is parsed and each line is pushed as a value onto the input stack (`}<inlineCode parentName="p">{`i`}</inlineCode>{`).
Numbers are parsed as number and everything else is a string.`}</p>
    <p>{`Stck can also be used programmatically with the `}<inlineCode parentName="p">{`run`}</inlineCode>{` function. The second
parameter is optional and is the value of the input stack. The contents of the
output stack are returned.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { run } from "stck";
run("i>o", [1]);
// => [1]
`}</code></pre>
    <h2>{`Example`}</h2>
    <p>{`If the you have the following program in `}<inlineCode parentName="p">{`example.stck`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`"hello ">o i>o "
">o
i>a i>a a+a a>o
`}</code></pre>
    <p>{`when run with the command`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`echo "1\\n2\\nworld" | yarn start examples/hello.stck
`}</code></pre>
    <p>{`The output will be`}</p>
    <pre><code parentName="pre" {...{}}>{`hello world
3
`}</code></pre>
    <h2>{`Source`}</h2>
    <p>{`Find the source on `}<a parentName="p" {...{
        "href": "https://github.com/coffee-cup/stck"
      }}>{`Github`}</a>{`.`}</p>
    <h2>{`Acknowledgements`}</h2>
    <p>{`Stck is heavily inspired by `}<a parentName="p" {...{
        "href": "https://esolangs.org/wiki/Kipple"
      }}>{`Kipple`}</a>{`. It also
takes inspiration from many languages on the `}<a parentName="p" {...{
        "href": "https://esolangs.org/wiki/Language_list"
      }}>{`esolang
wiki`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      